.future-font {
  font-family: Orbitron, sans-serif;
}

.grey-bg {
  background-color: #324153;
}

.cyan-text {
  color: #66fcf1;
}

.dark-cyan-text {
  color: #45a29e;
}

.cyan-border {
  border: 1px solid #45a29e;
}

.off-white-text {
  color: #c5c6c7;
}

.red-text {
  color: #b51a00;
}
/*# sourceMappingURL=index.fe2c7942.css.map */
